const VisitingFacultydata = [
    {
        id : 1,
        image : "./images/profimages/prof19.jpg",
        name : "   N B Ballal  ",
        link : "bhallal",
    },
   
   
]
export default VisitingFacultydata