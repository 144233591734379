import React from "react";

import Gallery from "../components/gallery";

const GalleryPage = () => {
  return (
    <>
      <div className="body">
        
        
        <Gallery />
        
      </div>
    </>
  );
};

export default GalleryPage;
