
import React from 'react'

// import underconst from './images/Under-construction.gif'
import Underconstruction from './underconstruction'

const Alumini = () => {
  return (
    <>
  
   <div><Underconstruction/></div>
   
  </>
  )
}

export default Alumini
