import React from "react";

import AccordianMain from "../components/AccordianMain";
import "./resources.css";

const Resources = () => {
  return (
    <>
      <div className="body">
       
       
        <div>
          <AccordianMain />
        </div>
        
       
      </div>
    </>
  );
};

export default Resources;
